/* eslint camelcase: "off" */
/* eslint import/first: "off" */

import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';
import { application } from './controllers/application';
import { Alert, Autosave, Dropdown, Tabs, Popover, Slideover, Modal } from 'tailwindcss-stimulus-components';

// Enable Rails' Unobtrusive JavaScript (UJS) helpers
Rails.start();

// Import and register TailwindCSS Components
application.register('alert', Alert);
application.register('autosave', Autosave);
application.register('dropdown', Dropdown);
application.register('tabs', Tabs);
application.register('popover', Popover);
application.register('slideover', Slideover);
application.register('modal', Modal);

// Import <details-dialog> element (modals)
import '@github/details-dialog-element';

import Admin__PaymentSearchesController from './controllers/admin/payment_searches_controller.js';
application.register('admin--payment-searches', Admin__PaymentSearchesController);

import Admin__StatsChartController from './controllers/admin/stats_chart_controller.js';
application.register('admin--stats-chart', Admin__StatsChartController);

import Admin__AuthController from './controllers/admin/auth_controller.js';
application.register('admin--auth', Admin__AuthController);

import Admin__RetailerBankExclusionsController from './controllers/admin/retailer_bank_exclusions.js';
application.register('admin--retailer-bank-exclusions', Admin__RetailerBankExclusionsController);

import ImmediateInputSubmitController from './controllers/immediate_input_submit_controller.js';
application.register('immediate-input-submit', ImmediateInputSubmitController);

import LocaltimeFormatController from './controllers/localtime_format_controller.js';
application.register('localtime-format', LocaltimeFormatController);

import Admin__BulkController from './controllers/admin/bulk_controller.js';
application.register('admin--bulk', Admin__BulkController);

import Admin__CohortDagController from './controllers/admin/cohort_dag_controller.js';
application.register('admin--cohort-dag', Admin__CohortDagController);

import Admin__DraggableController from './controllers/admin/draggable_controller.js';
application.register('admin--draggable', Admin__DraggableController);

import ToggleController from './controllers/toggle_controller.js';
application.register('toggle', ToggleController);

import DisableController from './controllers/disable_controller.js';
application.register('disable', DisableController);

import Admin__ReorderController from './controllers/admin/reorder_controller.js';
application.register('admin--reorder', Admin__ReorderController);

import Admin__ComboboxController from './controllers/admin/combobox_controller.js';
application.register('admin--combobox', Admin__ComboboxController);

import ClipboardController from './controllers/clipboard_controller.js';
application.register('clipboard', ClipboardController);

import Admin__ColorPreviewController from './controllers/admin/color_preview_controller.js';
application.register('admin--color-preview', Admin__ColorPreviewController);

import Admin__CashbackPercentController from './controllers/admin/cashback_percent_controller.js';
application.register('admin--cashback-percent', Admin__CashbackPercentController);

import JumpController from './controllers/jump_controller.js';
application.register('jump', JumpController);
